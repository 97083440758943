<!--
 * @Description: 设置对外开放类型
 * @Author: xiawenlong
 * @Date: 2021-05-18 16:03:56
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-18 17:04:02
-->
<template>
  <div class="teacher-open">
    <div class="tip">
      <p>注意事项：</p>
      <p>1.设置对外开放后，对应的讲师将展示给用户；</p>
      <p>2.用户可以对开放的讲师在线进行预约培训、直播、录播等；</p>
      <p>3.设置成功后，若用户未进行预约，支持修改；若用户预约成功，不可进行修改；</p>
      <p>4.若确实需要修改，联系客服处理，所造成的损失一切自行承担！</p>
    </div>
    <el-form ref="form" label-width="150px" :model="form" :rules="rules">
      <el-form-item label="是否对外开放：" prop="accessLevel">
        <el-radio-group v-model="form.accessLevel">
          <el-radio label="PUBLIC">是</el-radio>
          <el-radio label="PRIVATE">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.accessLevel == 'PUBLIC'"
        label="设置对外开放类型："
        prop="openScopeList"
      >
        <el-checkbox
          v-model="checkScopeAll"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="form.openScopeList" @change="handleCheckedScopeChange">
          <el-checkbox v-for="item in scopes" :key="item" :label="item">{{ item }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-checkbox v-model="isAgree" class="isAgree"
        >我确认阅读<span>《协议***********》</span>和<span>《隐私条款》</span></el-checkbox
      >
      <div class="btns">
        <el-button type="primary" @click="submit">确定授权</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
const scopes = ['讲师预约', '视频直播', '视频录播']
import { teacherOpen } from '@/api/teacher'
import to from 'await-to'
export default {
  name: 'TeacherOpen',
  data() {
    return {
      rules: {
        accessLevel: [{ required: true, message: '请选择是否对外开放', trigger: 'change' }],
        openScopeList: [{ required: true, message: '请设置对外开放类型', trigger: 'change' }],
      },
      isAgree: false,
      checkScopeAll: false,
      isIndeterminate: false,
      scopes,
      form: {
        accessLevel: 'PUBLIC',
        openScopeList: [],
        teacherIdList: [],
      },
    }
  },
  mounted() {
    this.form.teacherIdList = this.$route.query.ids.split(',')
  },
  methods: {
    handleCheckAllChange(val) {
      this.form.openScopeList = val ? this.scopes : []
      this.isIndeterminate = false
    },
    handleCheckedScopeChange(value) {
      let checkedCount = value.length
      this.checkScopeAll = checkedCount === this.scopes.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.scopes.length
    },
    submit() {
      if (!this.isAgree) return this.$message.warning('请先确认阅读协议')
      this.$refs['form'].validate(async valid => {
        if (valid) {
          console.log(this.form)
          this.loading = true
          const [, err] = await to(teacherOpen(this.form))
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('授权成功')
          this.$router.push('/teacher/list')
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-open {
  min-height: calc(100vh - 140px);
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 30px;
  .tip {
    width: 100%;
    height: 139px;
    background: #fff5f0;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #606266;
    box-sizing: border-box;
    padding: 14px 0 0 24px;
    margin-bottom: 30px;
    p {
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
  .isAgree,
  .btns {
    padding-left: 150px;
  }
  .isAgree {
    padding-top: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    line-height: 30px;
    margin-bottom: 20px;
    span {
      color: #ff7b33;
    }
  }
}
</style>
